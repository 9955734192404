(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.matches.controller:MatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice.invoicesgroup')
    .controller('invoicesgroup', invoicesgroup);

  function invoicesgroup(AclService,Invoicesgroup,townHalls, $mdToast, $filter, $scope, $state,excel, $timeout, $mdDialog) {
    var vm = this;
     var organisationList = angular.copy(townHalls);
    vm.isAdm=AclService.isAdmin();
    vm.applyFilters = applyFilters;
    vm.llistatpersones=[];
      vm.matches = [];
    vm.selected = [];
    vm.select = undefined;
    vm.dis=true;
    var template;
        
     
    function applyFilters() {
      vm.isFiltered = true;
      query( vm.iniDate, vm.endDate);
    }
    
     function query( iniDate, endDate) {
          var parameters = {
                initDate: moment(iniDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                isAdmin:AclService.isAdmin(),
                idAjuntament:townHalls[0].id
      };
       var an1=moment(vm.iniDate).format('YYYY');
       var an2=moment(vm.endDate).format('YYYY');
       if(an1===an2){
     vm.llistatpersones=Invoicesgroup.query(parameters);
 }
 else{
     alert("No es poden consultar dades de diferents anys.")
 }
    
     }
     
      $scope.exportToExcel=function(tableId){ // ex: '#my-table'
            var exportHref=excel.tableToExcel(tableId,'name');
            $timeout(function(){location.href=exportHref;},100); // trigger downloa
        };
    vm.imprimir=function(){
        var trato="";
        var c=0;
        
         angular.forEach(vm.llistatpersones, function (persona) {
          if (persona.selected) {
           trato=trato+"-"+persona.numinvoice+"/"+persona.idreferee;
            c++;
         
                 vm.persona=persona;
            // $scope.$watch(persona, function(e) { vm.persona = persona; });
            var an=moment(vm.iniDate).format('YYYY');
             var url =    $state.href('home.invoiceref', {personId: persona.idreferee,numinvoice:persona.numinvoice,anyo:an},{reload:true});
            // var printWin = window.open(url, '');
   //   printWin.document.write(s);
      
  //  printWin.document.write(template);
  //  printWin.document.close();
  //url='http://localhost:3000/#/home/referee-new-invoice/invoice/19/1/2020';
 
  var windowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
            var p=    window.open(url,'');
            
            
                 template = document.getElementById('invoicePrintPreview').innerHTML;
                 
   
       
            
 // var printWin = window.open('', '');
   //   printWin.document.write(s);
      
  //  printWin.document.write(template);
  //  printWin.document.close();
      //printWin.onload = function () {
      
          }
          
        });
        if (c===0) {alert("Si us plau, selecciona al menys una factura per imprimir");vm.dis=true;}
      
    };
    vm.enable=function(){
         vm.dis=false;
    };
  }
}());


